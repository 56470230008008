<template>
    <div>
        <h3>Nieuwe organisaties</h3>
        <el-table
        :data="organisations" style="width: 100%">
            <el-table-column
                    fixed
                    width="200"
                    label="Logo">
                    <template slot-scope="scope">
                        
                        <img v-if="scope.row.logo" :src="scope.row.logo.placeholder" />
                       
                    </template>
                    </el-table-column>
            <el-table-column
                    fixed
                    prop="name"
                    label="Naam">
                    </el-table-column>
            <el-table-column
                    
                    prop="reach"
                    label="Aantal animatoren">
                    </el-table-column>
            <el-table-column
                    
                    prop="contact.name"
                    label="Contactpersoon">
                    </el-table-column>
            <el-table-column
                    
                    prop="contact.email"
                    label="E-mail">
                    </el-table-column>
            <el-table-column
                    
                    prop="contact.website"
                    label="Website">
                    </el-table-column>
            <el-table-column
                    v-if="$store.state.core.user.role >= 2"
                    fixed="right"
                    label="Goedkeuring" width="120">
                    <template slot-scope="scope">
                        <el-button slot="reference" @click="addOrganisation(scope.row.id)" type="success" circle size="medium" :icon="loadings[scope.row.id] ? 'el-icon-loading' : 'el-icon-check'"></el-button>
                        <el-button slot="reference" @click="removeOrganisation(scope.row.id)" type="danger" circle size="medium" :icon="loadings[scope.row.id] ? 'el-icon-loading' : 'el-icon-delete'"></el-button>
                    </template>
            </el-table-column>
        </el-table>
    </div>
</template>
<script>
var moment = require('moment');
import {db, storageRef} from '@/CUSTOM/helpers/firebase'
import Vue from 'vue';
export default {
    name: 'ReviewNewOrganisations',
    data (){
        return {
            organisations: [],
            loadings: {}
        }
    },
    created(){
       this.load();
    },
    methods: { 
        load(){
             db.collection('new-organisations').where('approved', '==', null).get().then((snap) => {
            this.organisations = []
            snap.forEach((doc) => {
                var t = doc.data();
                t.id = doc.id;
                this.organisations.push(t)
            })
        })
        },
        addOrganisation(id){
            Vue.set(this.loadings, id, true);
            db.collection('new-organisations').doc(id).update({approved: true}).then(() =>{
                Vue.set(this.loadings, id, false);
                this.load();
            })
        },
        removeOrganisation(id){
            Vue.set(this.loadings, id, true);
            db.collection('new-organisations').doc(id).update({approved: false}).then(() =>{
                Vue.set(this.loadings, id, false);
                this.load();
            })
        }
    }

}
</script>