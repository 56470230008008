import firebase from 'firebase'
import 'firebase/firestore'

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyAtNF426GLPtVNNQCZCwd1vzZTFM-1ipuU",
    authDomain: "dag-van-de-animator-admin.firebaseapp.com",
    databaseURL: "https://dag-van-de-animator-admin.firebaseio.com",
    projectId: "dag-van-de-animator-admin",
    storageBucket: "dag-van-de-animator-admin.appspot.com",
    messagingSenderId: "955375300510",
    region: "europe-west1"
});

// Export the database for components to use.
// If you want to get fancy, use mixins or provide / inject to avoid redundant imports.

export const db = firebaseApp.firestore();
export const auth = firebaseApp.auth();
export const storage = firebaseApp.storage();
export const storageRef = firebaseApp.storage().ref();
export const functions = firebaseApp.functions("europe-west1");
export const app = firebaseApp
