<template>
    <div class="p-4">
        <el-card class="col-12 col-sm-6 col-md-4 mx-auto" v-if="!success">
            <h1>Change Password</h1>
            <el-form>
                <el-form-item>
                    <el-input type="password" :class="$v.oldPassword.$error ? 'invalid-input' : ''" name="oldPassword" v-model="oldPassword" placeholder="Old Password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-input type="password" :class="$v.newPassword.$error ? 'invalid-input' : ''" name="newPassword" v-model="newPassword" placeholder="New Password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-alert
                        v-if="error"
                        :title="error.message"
                        type="error">
                    </el-alert>
                    <el-button type="primary" class="w-100" @click.prevent="ChangePassword" :loading="loading">Change</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card v-if="success" class="col-12 col-sm-6 col-md-4 mx-auto">
            <h1>Password Changed!</h1>
            <el-button type="primary" @click="$router.push('/')">Done!</el-button>
        </el-card>
    </div>
</template>
<script>
import {auth} from '@/CUSTOM/helpers/firebase'
import {required} from 'vuelidate/lib/validators'
    export default {
        name: 'ChangePassword',
        data () {
            return {
                newPassword: '',
                oldPassword: '',
                error: null,
                success: false,
                loading: false
            }
        },
        created() {
            this.$emit('loading', false)
        },
        methods: { 
            ChangePassword () {
                if(!this.$v.$invalid) {
                    this.loading=true
                    const credential = auth.EmailAuthProvider.credential(
                        auth.currentUser.email, 
                        this.oldPassword
                    );
                    auth.currentUser.reauthenticateWithCredential(credential).then(() => {
                        return auth.currentUser.updatePassword(this.newPassword)
                    }).then(() => {
                        this.success = true
                        this.loading = false
                    }).catch((error) => {
                        this.loading = false
                        this.error = error
                        if(error.code == 'auth/user-not-found') {
                            this.success = true
                        }
                    });
                } else {
                    this.$v.$touch();
                }
                
            }
        },
        validations: {
            newPassword: {
                required
            },
            oldPassword: {
                required
            }
        }
    }
</script>