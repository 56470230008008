import Organisaties from './Organisaties'
import OrganisatieEdit from './OrganisatieEdit'
export const organisatieRoutes = [
  {
    path: '/organisaties',
    name: 'Organisaties',
    component: Organisaties,
    title: 'Organisaties',
    icon: 'el-icon-menu',
    meta: { requiresAuth: true, minRole: 1}
  },
  {
    path: '/organisatie/bewerken/:id',
    name: 'OrganisatieEdit',
    component: OrganisatieEdit,
    meta: { requiresAuth: true, minRole: 2}
  }
];