

const state = {
  blog: false
};

const mutations = {
 
};

const actions = {
 
};

export default {
  state,
  mutations,
  actions,
};
