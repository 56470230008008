<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/sponsors' }">Sponsors</el-breadcrumb-item>
            </el-breadcrumb>
            <h1 class="uk-align-left">Sponsors</h1>
            <div class="uk-align-right">
                <el-button @click="$router.push('/sponsor/bewerken/0')"  icon="el-icon-plus" circle type="primary"></el-button>
            </div>
            <el-table
                :data="sponsors"
                style="width: 100%">
                <el-table-column
                fixed
                prop="name"
                label="Naam">
                </el-table-column>
                <el-table-column
                    label="Laatste Update">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ convertDate(scope.row.lastEdit) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="$store.state.core.user.role >= 2"
                    fixed="right"
                    label="Bewerken" width="120">
                    <template slot-scope="scope">
                        <el-button type="success" @click="$router.push(`/sponsor/bewerken/${scope.row.id}`)" circle size="medium" icon="el-icon-edit"></el-button>
                        <el-popover
                            v-if="$store.state.core.user.role >= 3"
                            placement="top"
                            width="300"
                            trigger="hover">
                            <p><strong>Ben je zeker dat je deze sponsor wil verwijderen?</strong><br> Dit kan niet ongedaan gemaakt worden.</p>
                            <div style="text-align: right; margin: 0">
                                <el-button type="primary" size="mini" @click="remove(scope.row.id)">Ja</el-button>
                            </div>
                            <el-button slot="reference" type="danger" circle size="medium" :icon="loadings[scope.row.id] ? 'el-icon-loading' : 'el-icon-delete'"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
            
</template>
<script>
var moment = require('moment');
import {db} from '@/CUSTOM/helpers/firebase'
import imagePicker from '@/CORE/modules/MEDIA/ImagePicker'
import VueImage from '@/CORE/modules/MEDIA/VueImage'
import Vue from 'vue';
export default {
    name: 'sponsors',
    components: {imagePicker, VueImage},
    data () {
        return {
            sponsors: [],
            editing: false,
            loading: false,
            loadings: {}
        }
    },
    created () {
        db.collection('sponsors-2022').onSnapshot((snap) => {
            this.sponsors = []
            snap.forEach((doc) => {
                var t = doc.data();
                t.id = doc.id;
                Vue.set(this.loadings, t.id, false)
                this.sponsors.push(t)
            })
        })
    },
    methods: { 
        convertDate (timestamp) {
            console.log(moment.unix(timestamp))
            return moment.unix(timestamp).fromNow();
        },
        remove (id) {
            
            return db.collection('sponsors-2022').doc(id).delete().then(() => {
                UIkit.notification({message: 'Sponsor Verwijderd', status: 'danger'})
            })
        
        },
    }
}
</script>