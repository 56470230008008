<template>
    <div class="main-content">
        <div class="uk-padding">
            <ul class="uk-breadcrumb">
                <li><router-link to="/contact">Contact</router-link></li>
            </ul>
            <h1 class="uk-align-left">Contact</h1>
         
            <table class="uk-table">
                <thead>
                    <tr>
                        <th width="30"></th>
                        <th>Naam</th>
                        <th>Email</th>
                        <th>Tijdstip</th>
                        <th width="30"></th>
                        <th width="30"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr :key="id" v-for="(p, id) in projects">
                        <td><span uk-icon="icon: bolt; ratio: 1.2"></span></td>
                        <td>{{p.name}}</td>
                        <td>{{p.category}}</td>
                        <td>{{convertDate(p.lastEdit)}}</td>
                        <td>
                            <router-link :to="{ name: 'ProjectEdit', params: { id: id }}" class="uk-link" uk-icon="icon: cog"></router-link>
                        </td>
                        <td>
                            <a class="uk-link" @click.prevent="remove(id)" uk-icon="icon: trash"></a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import {db} from '@/CUSTOM/helpers/firebase'
export default {
    name: 'Contact',
    data () {
        return {
            contact: null
        }
    },
    created () {
        db.collection('contact').onSnapshot((snap) => {
            this.contact = {}
            snap.forEach((doc) => {
                this.contact[doc.id] = doc.data()
            })
        })
    },
    methods: {  
        remove (id) {
            UIkit.modal.confirm('Ben je zeker dat je dit bericht wil verwijderen?').then(() => {
               return db.collection('contact').doc(id).delete().then(() => {
                   UIkit.notification({message: 'Bericht Verwijderd', status: 'danger'})
               })
           });
        }
    }
}
</script>