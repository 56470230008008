<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/pages' }">Pagina's</el-breadcrumb-item>
            </el-breadcrumb>
            <h1 class="uk-align-left">Pagina's</h1>
            <div class="uk-align-right">
                <el-button @click="dialogPageVisible = true"  icon="el-icon-plus" circle type="primary"></el-button>
            </div>
        
            <el-table
                :data="pages"
                style="width: 100%">
                <el-table-column
                fixed
                prop="name"
                label="Naam">
                </el-table-column>
                <el-table-column
                label="Laatste Update">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ convertDate(scope.row.lastEdit) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                fixed="right"
                label="Bewerken"
                width="120">
                <template slot-scope="scope">
                    <el-button type="success" @click="$router.push(`/page/${scope.row.id}`)" circle size="medium" icon="el-icon-edit"></el-button>
                    <el-popover
                    v-if="$store.state.core.user && $store.state.core.user.role > 3"
                    placement="top"
                    width="160"
                    trigger="hover">
                    <p>Ben je zeker?</p>
                    <div style="text-align: right; margin: 0">
                        <el-button size="mini" type="text" @click="visible2 = false">Nee</el-button>
                        <el-button type="primary" size="mini" @click="removePage(scope.row.id)">Ja</el-button>
                    </div>
                    <el-button slot="reference" type="danger" circle size="medium" icon="el-icon-delete"></el-button>
                    </el-popover>
                    
                </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog title="New Page" :visible.sync="dialogPageVisible">
            <el-form>
            <el-form-item>
                <el-input v-model="newPage.name" autocomplete="off"></el-input>
            </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="dialogPageVisible = false">Cancel</el-button>
            <el-button type="primary" @click="add">New page</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
var moment = require('moment');
import {db} from '@/CUSTOM/helpers/firebase'
export default {
    name: 'Pages',
    data () {
        return {
            pages: null,
            dialogPageVisible: false,
            newPage: {
                name: null
            }
        }
    },
    created () {
        db.collection('pages').onSnapshot((snap) => {
            this.pages = []
            snap.forEach((doc) => {
                var t = doc.data()
                t.id = doc.id
                this.pages.push(t)
            })
        })
    },
    methods: { 
        add () {
            this.newPage.created = Math.floor(Date.now() / 1000)
            this.newPage.lastEdit = Math.floor(Date.now() / 1000)
            this.newPage.id = this.newPage.name.toLowerCase().trim().split(' ').join('-')
            db.collection('pages').doc(this.newPage.id).get().then(doc => {
                if(doc.exists) {
                    this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Error!', message: 'A page with this name already exists. Please choose a different name.', status: 'error', duration: 0})
                    throw new Error
                } else {
                    return db.collection('pages').doc(this.newPage.id).set(this.newPage)
                }
            }).then(() => {
                this.dialogPageVisible = false
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Success!', message: 'Page added!', status: 'success'})
            }).catch(() => {
                this.dialogPageVisible = false
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Something went wrong.', message: 'Something went wrong while adding a new page.', status: 'error', duration: 0})
            })
            
        },
        removePage(id){
            if(this.$store.state.core.user.role > 3) {
                return db.collection('pages').doc(id).delete().then(() => {
                    this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Page deleted', status: 'success'})
                });
            } else {
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Error!', message: 'You have missing or insufficient permissions to remove this page!', status: 'error', duration: 0})
            }        
        },
        cancel () {
            this.newPage = {
                name: null
            }
        },
        convertDate (timestamp) {
            return moment.unix(timestamp).fromNow();
        }
    }
}
</script>