<template>
    <el-card class="col-12 col-sm-6 col-md-4 mx-auto vertical-center">
        <h1 class="uk-card-title">LOGIN</h1>
        <el-form>
            <el-form-item>
                <el-input type="text" :class="$v.email.$error ? 'invalid-input' : ''" name="username" v-model="email" placeholder="email"/>
            </el-form-item>
            <el-form-item>
                <el-input  type="password" :class="$v.password.$error ? 'invalid-input' : ''" name="password" v-model="password" placeholder="password"/>
            </el-form-item>
            <el-form-item>
                <el-alert
                    v-if="error"
                    :title="errorMessages[error.code] || error.code || error"
                    type="error">
                </el-alert>
                <el-button type="success" class="w-100" @click.prevent="login" :loading="loading">Login</el-button>
            </el-form-item>
            <!-- <el-form-item>
                <el-button class="w-100" :loading="loading" type="primary" @click.prevent="loginFb()">Login with Facebook</el-button>
            </el-form-item> -->
            <el-form-item>
                <el-button class="w-100" :disabled="loading" type="text" @click="$router.push('password-forgotten')">Forgot Password?</el-button>
            </el-form-item>
        </el-form>
    </el-card>
    
</template>
<script>
import {auth} from '@/CUSTOM/helpers/firebase'
import {required, email} from 'vuelidate/lib/validators'
    export default {
        name: 'login',
        data () {
            return {
                email: '',
                password: '',
                error: null, 
                loading: false,
                errorMessages: {
                    'auth/invalid-email': 'Gelieve een correct emailadres in te vullen',
                    'auth/user-not-found': 'De combinatie van het email adres en wachtwoord klopt niet',
                    'auth/wrong-password': 'De combinatie van het email adres en wachtwoord klopt niet',
                }
            }
        },
        created() {
            this.$emit('loading', false)
        },
        methods: { 
            login () {
                if(!this.$v.$invalid) {
                    this.loading = true
                    auth.signInWithEmailAndPassword(this.email, this.password).then(auth => {
                        this.$router.push('/')
                    }).catch(error => {
                        this.loading = false
                        var errorCode = error.code;
                        var errorMessage = error.message;
                        this.error = error
                    });
                } else {this.$v.$touch()}
            },
            // loginFb () {
            //     var provider = new firebase.auth.FacebookAuthProvider();
            //     provider.setCustomParameters({
            //         'display': 'popup'
            //     });
            //     this.loading = true
            //     auth.signInWithPopup(provider).then(r => {
            //         return db.doc('users/' + r.user.uid).get()
            //     }).then(u => {
            //         var user = u.data()
            //         this.$router.push('/')
            //         this.loading = false
            //     }).catch(error => {
            //         if(auth.currentUser){
            //             auth.currentUser.delete()
            //         }
            //         this.loading = false
            //         this.error = 'Kon niet aanmelden met Facebook. Controleer of je reeds een account hebt en deze gekoppeld is aan je Facebook Account';
            //     })
            // }
        },
        validations: {
            email: {
                required,
                email
            },
            password: {
                required
            }
        }
    }
</script>