<template>
    <div class="px-0 d-flex flex-wrap">
        <div :key="id" v-for="(i, id) in images" class="col-12 col-sm-6 col-md-3 py-2 pr-2">
            <el-card :body-style="{padding: 0}" >
                <img v-if="i" :src="i.medium" :alt="i.alt" :title="i.title" /> 
            </el-card>
        </div>
    </div>
</template>
<script>
export default {
    name:"VueImage",
    props: {
        img: {
            required: true
        },
        size: {
            required: false,
            default: 2
        }
    },
    computed: {
        images () {
            if(Array.isArray(this.img)) {
                return this.img
            } else {
                return [this.img]
            }
            
        }
    }
}

if (typeof Array.isArray === 'undefined') {
  Array.isArray = function(obj) {
    return Object.prototype.toString.call(obj) === '[object Array]';
  }
};
</script>