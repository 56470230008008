import Review from './Review'
export const reviewRoutes = [
  {
    path: '/review',
    name: 'Review',
    component: Review,
    title: 'Review',
    icon: 'el-icon-star-on',
    meta: { requiresAuth: true, minRole: 1}
  }
];