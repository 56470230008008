<template>
    <div  class="p-0 p-sm-1 p-md-4">
        <el-card v-if="this.$store.state.core.user">
            <h3>Registraties</h3>
            <div class="uk-margin">
                <el-button type="primary" :loading="downloading" v-if="$store.state.core.user.role >= 3"  @click="getExcel">Download Registraties</el-button>
                <br><label>Maximaal aantal registraties</label>
                <el-input type="text" style="width: 200px;" v-model="max" :loading="maxLoading"></el-input>
                <el-button type="primary" :loading="maxLoading"  @click="changeMax">Opslaan</el-button>
            </div>
            <h3>Applausmeter</h3>
            <div class="uk-margin">
                <label>Doel</label>
                <el-input type="text" style="width: 200px;" v-model="target" :loading="targetLoading"></el-input>
                <el-button type="primary" :loading="targetLoading"  @click="changeTarget">Opslaan</el-button>
            </div>
            <ReviewNewOrganisations></ReviewNewOrganisations>
            <h3 v-if="$store.state.core.user.role >= 3">Beheer Data</h3>
            <div v-if="$store.state.core.user.role >= 3" class="uk-margin" >
                <label>Plak de URL van het applaus dat je wil verwijderen</label>
                <div uk-grid>
                    <div class="uk-width-4-5">
                        <el-input type="text" :disabled="loading" v-model="url"  />
                        <p v-if="error" class="uk-text-danger">Dit object bestaat niet meer</p>
                    </div>
                    
                    <div class="uk-width-1-5">
                        <el-button class="uk-button uk-button-primary" :loading="loading" @click="processUrl">Zoeken</el-button>
                    </div>
                </div>
            </div>
            <el-card v-if="object">
                <el-button class="float-right" type="danger" @click="removeObject" circle size="medium" icon="el-icon-delete"></el-button>
                <h3>Applaus van {{object.from}}</h3>
                <img style="width: 200px" :src="object.thumbnail"/>
                
            </el-card>
            
        </el-card>
    </div>
</template>
<script>
var moment = require('moment');
import {db, storageRef} from '@/CUSTOM/helpers/firebase'
import Vue from 'vue';
import XLSX from 'xlsx';
import ReviewNewOrganisations from './ReviewNewOrganisations';
export default {
    name: 'Review',
    components:{ReviewNewOrganisations},
    data () {
        return {
           url: null,
           type: null, 
           target: null,
           max: null,
           targetLoading: true,
           maxLoading: true,
           id: null,
           object: null,
           typeMap: {
               'acties': 'actions',
               'tekeningen': 'tekeningen',
               'applaus': 'applaus'
           },
           organisations: null,
           loading: false,
           downloading: false,
           tdownloading: false,
           deleting: false,
           error: false,
        }
    },
    created(){
        db.collection('organisations').orderBy('name').get().then(snap => {
            var o = {}
            snap.forEach((doc) => {
                o[doc.id] = doc.data()
            })
            this.organisations = o
        })
        db.collection('stats').doc('applaus').get().then((snap) => {
            this.target = snap.data().target;
            this.targetLoading = false;
        })
        db.collection('stats').doc('wedstrijd-2024').get().then((snap) => {
            this.max = snap.data().max;
            this.maxLoading = false;
        })
    },
    methods: { 
        processUrl() {
            this.reset();
            if(this.url && this.url.length > 0) {
                this.loading = true
                var str = this.url.split('/')
                this.type = str[3]
                this.id = str[4]
                this.loadObject()
            }
            
        },
        reset () {
            this.type = null
            this.id = null
            this.object = null
            this.deleting = false
            this.error = false
        },
        removeObject(){
            this.deleting= true;
            db.collection(this.typeMap[this.type]).doc(this.id).delete().then(() => {
                this.url = null
                this.reset()
            })
        },
        loadObject(){
            db.collection(this.typeMap[this.type]).doc(this.id).get().then((r) => {
                this.object = r.data()
                this.loading = false
            }).catch(error => {
                this.loading = false
                this.error = true
            })
        },
        changeTarget(){
            this.targetLoading = true
            db.collection('stats').doc('applaus').update({target: parseInt(this.target)}).then(() => {
                this.targetLoading = false
            })
        },
        changeMax(){
            this.maxLoading = true
            db.collection('stats').doc('wedstrijd-2024').update({max: parseInt(this.max)}).then(() => {
                this.maxLoading = false
            })
        },
        getExcel () {
            this.downloading = true
            this.prepareData().then(res => {
                var ws = XLSX.utils.aoa_to_sheet(res);
                ws['!autofilter'] = { ref: `A1:${toColumnName(Object.keys(res[0]).length)}${res.length}` };
                var wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, "Registraties DVDA 2024");
                XLSX.writeFile(wb, `registraties.xlsx`);
                this.downloading = false
                return true
            })
        },
        saveByteArray(blob, url){
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        downloadImages () {
            db.collection('applaus').where('processed', '==', true).get().then(res => {
                res.forEach(q => {
                    var xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        var blob = xhr.response;
                        saveByteArray(blob, `${q.id}.jpg`)
                    };
                    xhr.open('GET', q.data().url);
                    xhr.send();
                })
            })
        },
        
        prepareData() {
            return Promise.all([db.collection('wedstrijd-2024').get()]).then(res => {
                var dataArray = []
                var data = {}
                var keys = ['ID', 'Tijdstip', 'Organisatie', 'Voornaam', 'Naam', 'Geboortedatum', 'Email', 'Straat', 'Huisnummer', 'Stad', 'Postcode', 'Akkoord Privacy Verklaring', 'Welk item zit er in jouw rugzak als animator? (bv. brooddoos, oortjes, kaartspel…)', 'Welke ervaringen draag jij mee in je rugzak, die je kan inzetten als animator? (bv. vaak van school veranderd, tweetalig opgevoed…)', 'Waar vier je Dag van de Animator op 25 juli 2024? (op kamp, op het speelplein, thuis…)', 'Mogen we je contacteren om je verhaal te delen?']
                dataArray.push(keys)
                res[0].forEach(q => {
                    data[q.id] = q.data()
                })
                Object.keys(data).forEach(k => {
                    var d = data[k]
                    var ja = {true: 'ja', false: 'nee'}
                    var oName = '';
                    if(this.organisations[d.organisation]){
                        oName = this.organisations[d.organisation].name
                    } else {
                        oName = d.organisation
                    }
                    var r = [k, moment(d.created.seconds*1000).format('DD/MM/YYYY hh:mm'), oName, d.firstname, d.lastname, d.birthday, d.email, d.location.street, d.location.number, d.location.city, d.location.postal, d.policy || false, d.rugzak, d.ervaring, d.locatie, d.contact || false]
                    d.id = k
                    dataArray.push(r)
                })               
                return dataArray  
            })
        },
    }
}
function toColumnName(num) {
  for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}
</script>