<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card>
            <h1>Media</h1>
        </el-card>
        <imageUploader></imageUploader>
        <el-card v-if="Object.keys(processing).length > 0" class="my-5 p-3">
            <p><i class="el-icon-loading mr-3"></i>{{`${Object.keys(processing).length} photo${Object.keys(processing).length>1?'s':''} ${Object.keys(processing).length>1?'are':'is'} currently processing`}}</p>
        </el-card>
        <el-card>
            <div class="d-flex flex-wrap">
                <div class="col-12 col-sm-6 col-md-3 col-lg-2 p-3" :key="id" v-for="(m, id) in media">
                    <el-card :body-style="{padding: 0}">
                        <el-button v-if="$store.state.core.user && $store.state.core.user.role >= 3" type="danger" class="corner-right" icon="el-icon-delete" circle @click.prevent="deleteImage(id)"></el-button>
                        <img class="w-100 image" :src="m.small">
                    </el-card>
                </div>
            </div>
        </el-card>
    </div>
</template>
<script>
import {db, storage} from '@/CUSTOM/helpers/firebase'
import imageUploader from '@/CORE/modules/MEDIA/ImageUploader'
export default {
    name:'MediaOverview',
    components: {imageUploader},
    data () {
        return {
            media: null,
            processing: {},
            selected: null,
            formats: ['placeholder', 'small', 'medium', 'large', 'extralarge', 'url']
        }
    },
    created () {
        db.collection('media').where("processed", "==", true).onSnapshot((snap) => {
            this.media = {}
            snap.forEach((doc) => {
                this.media[doc.id] = doc.data()
            })
        })
        db.collection('media').where("processed", "==", false).onSnapshot((snap) => {
            this.processing = {}
            snap.forEach((doc) => {
                this.processing[doc.id] = doc.data()
            })
        })
    },
    methods: { 
        select (id) {
            this.selected = id
        },
        save () {
            db.collection('media').doc(this.selected).update(this.media[this.selected]).then(success => {
                this.$store.dispatch('PUSH_NOTIFICATION', {message: 'Afbeelding data aangepast', status: 'success'})
                this.selected = null
            }).catch(error => {
                throw error;
            })
        },
        deleteImage (id) {
            if(this.$store.state.core.user.role >= 3) {
                this.$confirm('Are you sure you want to delete this image?').then(_ => {
                    var promise = []
                    for (var f in this.formats) {
                        if(storage.refFromURL(this.media[id][this.formats[f]])) {
                            promise.push(storage.refFromURL(this.media[id][this.formats[f]]).delete())
                        }
                        
                    }
                    Promise.all(promise).then(success => {
                        this.selected = null
                        return db.collection('media').doc(id).delete()
                    }).then(success => {
                        this.$store.dispatch('PUSH_NOTIFICATION', {message: 'Foto verwijderd', status: 'danger'})
                    }).catch(error => {
                        this.$store.dispatch('PUSH_NOTIFICATION', {message: 'Er ging iets fout...', status: 'danger'})
                        throw error
                    })  
                }).catch(_ => {})
                
            }          
        },
        deleteProcessingImage (id) {
            if(this.$store.state.core.user.role >= 3) {
                UIkit.modal.confirm('Deze foto is nog aan het verwerken, ben je zeker dat je dit wilt annuleren?').then(() => {
                    var promise = []
                    var m = this.media[id]
                    for (var f in this.formats) {
                        if(m[f] != undefined && storage.refFromURL(this.media[id][this.formats[f]])) {
                            promise.push(storage.refFromURL(this.media[id][this.formats[f]]).delete())
                        }
                        
                    }
                    Promise.all(promise).then(success => {
                        return db.collection('media').doc(id).delete()
                    }).then(success => {
                        this.$store.dispatch('PUSH_NOTIFICATION', {message: 'Foto verwijderd', type: 'danger'})
                    }).catch(error => {
                        this.$store.dispatch('PUSH_NOTIFICATION', {message: 'Er ging iets fout...', type: 'danger'})
                        throw error
                    })  
                }, () => {
                    console.log('Rejected.')
                });
                
            }     
        }
    }
}
</script>