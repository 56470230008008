<template>
    <div>
        <el-button class="d-block mx-auto my-3" type="primary" @click="modalVisible=true"><i class="el-icon-plus"></i> Add Field</el-button>
        <el-dialog title="New Field" :visible.sync="modalVisible">
            <el-form>
                <el-form-item label="Field Key">
                    <el-input :class="$v.newField.id.$error ? 'invalid-input' : ''" v-model="newField.id"></el-input>
                </el-form-item>
                <el-radio-group :class="$v.newField.type.$error ? 'invalid-input' : ''" v-model="newField.type">
                    <el-radio-button label="text">Text</el-radio-button>
                    <el-radio-button label="img">Image</el-radio-button>
                    <el-radio-button label="link">Link</el-radio-button>
                    <el-radio-button label="wysiwyg">Wysiwyg</el-radio-button>
                </el-radio-group>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="modalVisible = false">Cancel</el-button>
            <el-button type="primary" @click="addField">Add Field</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
var moment = require('moment');
import Vue from 'vue';
import firebase from 'firebase'
import {db} from '@/CUSTOM/helpers/firebase'
import {required, email} from 'vuelidate/lib/validators'

export default {
    name: 'AddFieldModal',
    props: ['sections', 'section', 'page'],
    data () {
        return {
            modalVisible: false,
            componentID: null,
            activeName: 'text',
            newField: {
                type: 'text',
                id: null
            },
        }
    },
    created () {
        this.componentID = Math.floor((Math.random() * 100) + 1)
    },
    methods: { 
        addField () {
            if(this.newField.type == 'link'){
                this.newField.value = {}
            } else {
                this.newField.value = ''
            }
            if(!this.sections[this.section]['fields']){
                this.sections[this.section]['fields'] = {}
            }
            this.sections[this.section]['fields'][this.newField.id] = {value: this.newField.value, type: this.newField.type, order: Object.keys(this.sections[this.section]['fields']).length+1, 'author': this.$store.state.core.user.uid, 'lastEdit': Math.floor(Date.now() / 1000)}
            db.collection('pages').doc(this.page).collection('sections').doc(this.sections[this.section].ID || this.sections[this.section].id).update(this.sections[this.section]).then(res => {
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Field Added!', status: 'success'})
                this.$router.push(`/page/${this.page}/section/${this.sections[this.section].ID || this.sections[this.section].id}/field/${this.newField.id}`)
                this.updateEditTimestamp()
            })
            
        },
        updateEditTimestamp () {
            db.collection('pages').doc(this.page).update({lastEdit: Math.floor(Date.now() / 1000)})
        }
    },
    validations: {
        newField: {
            id: {
                required
            },
            type: {
                required
            }
        }
    }
}
</script>