<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/users' }">Gebruikers</el-breadcrumb-item>
            </el-breadcrumb>
            <h1 class="uk-align-left">Gebruikers</h1>
            <div class="uk-align-right">
                <el-button @click="newUserFormVisible = true"  icon="el-icon-plus" circle type="primary"></el-button>
            </div>
            <el-table
                :data="users"
                style="width: 100%">
                <el-table-column
                fixed
                prop="displayName"
                label="Naam">
                </el-table-column>
                <el-table-column
                label="Email">
                    <template slot-scope="scope">
                        <i class="el-icon-mail"></i>
                        <a :href="`mailto:${scope.row.email}`">{{scope.row.email}}</a>
                    </template>
                </el-table-column>
                <el-table-column
                label="Rol">
                    <template slot-scope="scope">
                        <span v-if="roles[scope.row.id]">{{userRoles[roles[scope.row.id].role-1]}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                fixed="right"
                label="Bewerken">
                <template slot-scope="scope">
                    <el-button type="primary" @click="$router.push(`/user/view/${scope.row.id}`)" circle size="medium" icon="el-icon-view"></el-button>
                    <el-button type="success" @click="$router.push(`/user/edit/${scope.row.id}`)" circle size="medium" icon="el-icon-edit"></el-button>
                    <el-popover
                        placement="top"
                        width="300"
                        trigger="hover">
                        <p><strong>Ben je zeker dat je deze gebruiker wil verwijderen?</strong><br> Dit kan niet ongedaan gemaakt worden.</p>
                        <div style="text-align: right; margin: 0">
                            <el-button type="primary" size="mini" @click="removeUser(scope.row.id)">Ja</el-button>
                        </div>
                        <el-button slot="reference" type="danger" circle size="medium" :icon="loadings[scope.row.id] ? 'el-icon-loading' : 'el-icon-delete'"></el-button>
                    </el-popover>
                </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-dialog title="Nieuwe Gebruiker" :visible.sync="newUserFormVisible">
            <el-form>
                <el-form-item label="Voornaam" >
                    <el-input v-model="newInvite.firstname" :disabled="inviteLoading" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="Achternaam" >
                    <el-input v-model="newInvite.lastname" :disabled="inviteLoading" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="email" >
                    <el-input v-model="newInvite.email" :disabled="inviteLoading" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="newUserFormVisible = false">Annuleren</el-button>
                <el-button type="primary" @click="invite" :loading="inviteLoading">Uitnodigen</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Vue from 'vue'
import {db, auth, functions} from '@/CUSTOM/helpers/firebase'
export default {
    name: 'users',
    data () {
        return {
            newInvite: {
                email: null,
                firstname: null,
                lastname: null,
                accepted: true,
            },
            loadings: {},
            newUserFormVisible: false,
            inviteLoading: false,
            users: [],
            roles: {},
            newUsers: {},
            userRoles: ["Gebruiker", "Moderator", "Beheerder", "Admin"]
        }
    },
    methods: { 
        invite () {
            console.log("HERE HER HERE");
            this.inviteLoading = true
            console.log("HERE HER HERE");
            functions.httpsCallable('createUser')(this.newInvite).then((res) => {
                this.cancel()
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Success!', status: 'success', message: 'De gebruiker werd succesvol toegevoegd.'})
            }).catch(error => {
                this.cancel()
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Gebruiker toevoegen mislukt!', status: 'error', message: error.message, duration: 0})
            })
        },
        removeUser(id){
            Vue.set(this.loadings, id, true)
            auth.currentUser.getIdTokenResult().then((idTokenResult) => {
                if (!!idTokenResult.claims.admin) {
                    functions.httpsCallable('removeUser')(id).then((res) => {
                        this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Success!', status: 'success', message: 'De gebruiker werd succesvol verwijderd.'})
                    }).catch(error => {
                        this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Gebruiker verwijderen mislukt!', status: 'error', message: error.message, duration: 0})
                    })
                } else {
                    this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Gebruiker verwijderen mislukt!', status: 'error', message: 'Je beschikt over de nodige rechten om een gebruiker te kunnen verwijderen.', duration: 0})
                }
            })
        },
        cancel () {
            this.inviteLoading = false
            this.newUserFormVisible = false
            this.newInvite = {
                email: null,
                firstname: null,
                lastname: null,
                accepted: true
            }
        }
    },
    created () {
        db.collection('users').onSnapshot((snap) => {
            this.users = []
            snap.forEach((doc) => {
                var t = doc.data()
                t.id = doc.id
                Vue.set(this.loadings, t.id, false)
                this.users.push(t)
            })
        })
        db.collection('users-secure').onSnapshot((snap) => {
            snap.forEach((doc) => {
                Vue.set(this.roles, doc.id, doc.data())
            })
        })
    }
}
</script>