<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card v-if="o">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/sponsors' }">sponsors</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'sponsorEdit', params: { id: $route.params.id }}">{{o.name}}</el-breadcrumb-item>
            </el-breadcrumb>
            <h1>Bewerk sponsor</h1>
            <el-form>
                <el-form-item label="Naam">
                    <el-input v-model="o.name" :disabled="editing" placeholder="Full Name"></el-input>
                </el-form-item>
                <el-form-item label="ID">
                    <el-input v-model="o.ID" :disabled="true" placeholder="ID"></el-input>
                </el-form-item>
                <el-form-item label="Logo">
                    <image-picker key="logo" :multiple="false" v-model="o.logo"></image-picker>
                    <vue-image :img="o.logo"/>
                </el-form-item>
                <el-form-item label="Gallerij">
                    <image-picker :multiple="true" v-model="o.gallery"></image-picker>
                    <vue-image :img="o.gallery"/>  
                </el-form-item>
                <el-form-item>
                     <trumbowyg v-model="o.inhoud"></trumbowyg>
                </el-form-item>
                
                <h3>Getuigenissen</h3>
                <div :key="k" v-for="(g, k) in o.getuigenissen">
                    <h4>Getuigenis {{k+1}}</h4>
                    <el-button class="float-right" type="danger" @click="removeGetuigenis(k)" circle size="medium" icon="el-icon-delete"></el-button>
                    <el-form-item label="Foto">
                        <image-picker key="foto" :multiple="false" v-model="g.foto"></image-picker>
                        <vue-image :img="g.foto"/>
                    </el-form-item>
                    <el-form-item >
                         <trumbowyg v-model="g.inhoud"></trumbowyg>
                    </el-form-item>
                </div>
                <el-form-item>
                    <el-button class="center" type="secondary" @click="nieuweGetuigenis">Nieuwe Getuigenis</el-button>
                </el-form-item>
                <!-- <h3>Contact Gegevens</h3>
                <el-form-item label="Contact persoon">
                    <el-input
                        placeholder="Contact Persoon"
                        v-model="o.contact.name">
                    </el-input>
                </el-form-item>
                <el-form-item label="Telefoonnummer">
                    <el-input
                        placeholder="Telefoonnummer"
                        v-model="o.contact.phone">
                    </el-input>
                </el-form-item>
                <el-form-item label="Email">
                    <el-input
                        placeholder="Email"
                        v-model="o.contact.email">
                    </el-input>
                </el-form-item>
                <el-form-item label="Website">
                    <el-input
                        placeholder="Website"
                        v-model="o.contact.website">
                    </el-input>
                </el-form-item>
                <el-form-item label="Adres">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="adres"
                        v-model="o.contact.address">
                    </el-input>
                </el-form-item> -->
                <el-form-item>
                    <el-button class="w-100" type="primary" :loading="loading" @click="update">Opslaan</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import {db} from '@/CUSTOM/helpers/firebase'
import imagePicker from '@/CORE/modules/MEDIA/ImagePicker'
import VueImage from '@/CORE/modules/MEDIA/VueImage'
import Trumbowyg from 'vue-trumbowyg';
  
  // Import editor css
  import 'trumbowyg/dist/ui/trumbowyg.css';
    export default {
        name: 'sponsorEdit',
        components: {imagePicker, VueImage, Trumbowyg},
        data () {
            return {
                    o: {
                    name: null,
                    ID: null,
                    gallery: [],
                    getuigenissen: []
                },
                editing: false,
                loading: false
            }
        },
        methods: { 
            update () {
                this.loading = true
                if(this.editing){
                    this.o.lastEdit = Math.floor(Date.now() / 1000)
                    db.collection('sponsors-2022').doc(this.$route.params.id).set(this.o, {merge: true}).then(res => {
                        this.loading = false
                        this.$router.push('/sponsors')
                    })
                } else {
                    this.createID();
                    this.o.created = Math.floor(Date.now() / 1000)
                    this.o.lastEdit = Math.floor(Date.now() / 1000)
                    db.collection('sponsors-2022').doc(this.o.ID).set(this.o).then(res => {
                        this.loading = false
                        this.$router.push('/sponsors')
                    })
                }
                
            },
            nieuweGetuigenis(){
                this.o.getuigenissen.push({foto: '', inhoud: ''})
            },
            removeGetuigenis(k){
                this.o.getuigenissen.splice(k, 1);
            },
            createID(){
                this.o.ID = this.o.name.split(' ').join('-').toLowerCase();
            }
        },
        created () {
            if(this.$route.params.id == 0) {
                this.editing = false
            } else {
                this.editing = true
                db.collection('sponsors-2022').doc(this.$route.params.id).onSnapshot((snap) => {
                    this.o = snap.data()
                })
            }
           
           
        }
}
</script>