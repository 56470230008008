<template>
    <el-card class="p-3 my-5">
        <h3>Upload</h3>
        <el-upload
            action=""
            :http-request="startUpload"
            :file-list="files"
            multiple>
            <el-button class="float-left" size="small" type="primary">Click to upload</el-button>
            <div slot="tip" class=" pb-3 el-upload__tip">jpg/png files only.</div>
        </el-upload>
        <el-progress v-if="this.files.length > 0" :stroke-width="18" :percentage="progress" :status="progress == 100 ? 'success' : ''"></el-progress>
    </el-card>
</template>
<script>
import Vue from 'vue'
import firebase from 'firebase'
import {db, storageRef} from '@/CUSTOM/helpers/firebase'
export default {
    name: 'ImageUploader',
    props: {
        showForm: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            files: [],
            loaded: [],
            URLS: {},
            uploaded: 0,
        }
    },
    methods: { 
        upload (file, index) {
            Vue.set(this.loaded, index, 0)
            var id = Math.floor(Math.random() * 90 + 10).toString()
            var extention = file.name.slice((file.name.lastIndexOf(".") - 1 >>> 0) + 2)
            var name = file.name.slice(0, -(extention.length + 1)) + "_" + id + '.' + extention;
            var uploadTask = storageRef.child('uploads/'+name).put(file)
            var downloadURL = '';
            uploadTask.on('state_changed', snap => {
                Vue.set(this.loaded, index , snap.bytesTransferred / snap.totalBytes * 100)
            }, (error) => {
                throw error
            }, (res) => {
                Vue.set(this.loaded, index, 100)
                uploadTask.snapshot.ref.getDownloadURL().then((durl) => {
                    downloadURL = durl
                    return db.collection('media').add({url: downloadURL, alt: "", title: "", caption: "", description: "", uploaded: Math.floor(Date.now() / 1000), processed: false})
                }).then(success => { 
                    this.uploaded++;
                    this.URLS[success.id] = {url: downloadURL, alt: "", title: "", caption: "", description: ""}
                    var metadata = {
                        customMetadata: {
                            'mediaId': success.id
                        }
                    }
                    return storageRef.child('uploads/'+name).updateMetadata(metadata)
                }).then(res => {
                    this.$store.dispatch('PUSH_NOTIFICATION', {title:'Success!', message: 'Afbeelding upgeload', status: 'success'})
                    if(this.uploaded == this.files.length) {
                        this.$emit('uploaded', this.URLS);
                    }
                }).catch(error => {
                    console.log(error)
                    storageRef.child('media/'+file.name).delete().then(succ => {
                        console.log('removed image due to an error')
                    }).catch (error => {
                        console.log('unable to remove image after error.')
                        console.log('error')
                    })
                })
            });
        },
        startUpload (e) {
            this.files.push(e.file)
            this.upload(e.file, this.files.length - 1)
        },
        clear () {
            this.files = []
            this.loaded = []
            this.URLS = {}
        }
    },
    computed: {
        progress () {
            var p = 0;
            this.loaded.forEach(a => {
                p+=a
            })
            return Math.floor((p/(this.files.length || 0)) || 0)
        }
    }
}
</script>
<style scoped>
    
</style>