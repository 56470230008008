<template>
    <el-aside :class="isCollapsed ? 'aside-collapsed' : 'aside-not-collapsed'" class="transition-all">
        <el-menu :collapse="isCollapsed" :default-active="$route.path"  class="el-menu-side pt-3 transition-all" mode="vertical" >
            
            <el-menu-item :key="k" v-for="(r, k) in routes" @click="$router.push(r.path)" :index="r.path">
                <i :class="r.icon || 'el-icon-setting'"></i>
                <span slot="title">{{r.title}}</span>
            </el-menu-item>
            <span class="el-menu-item-bottom">
                <el-menu-item v-if="$store.state.core.user && $store.state.core.user.role > 3" @click="$router.push('/settings')" index="/settings">
                    <i class="el-icon-setting"></i>
                    <span slot="title">Configuratie</span>
                </el-menu-item>
                <el-menu-item class="collapse-control transition-all" @click="isCollapsed = !isCollapsed" index="0">
                    <i class="h2 el-icon-arrow-left"></i>
                </el-menu-item>
            </span>
        </el-menu>
    </el-aside>
</template>
<script>
export default {
    name:"sideMenu",
    data () {
        return {
            activeIndex: "1",
            isCollapsed: true
        }
    },
    computed: {
        routes: function () {
            var routes = [];
            for (var i in this.$router.options.routes) {
                if (!this.$router.options.routes.hasOwnProperty(i)){
                    continue
                }
                var route = this.$router.options.routes[i];
                if(route.hasOwnProperty('title') && (this.$store.state.core.user && this.$store.state.core.user.role >= route.meta.minRole) && (route.title && this.$store.state.settings[route.title.toLowerCase()] != false)) {
                    routes.push(route);
                }
            }
            return routes;
        }
    }
}
</script>
<style lang='scss' scoped>
.el-aside {
    max-height: calc(100vh - 60px);
}
.el-menu-item-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    
    .collapse-control i {
        position: absolute;
        right: 15px;
    }
}
.aside-collapsed {
  width: 64px !important;
  .collapse-control {
      i {
          transform: rotate(180deg)
      }
  }
}
.aside-not-collapsed {
  width: 200px !important;
  .collapse-control {
      i {
          transform: rotate(0deg)
      }
  }
}
</style>