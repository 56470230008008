<template>
  <div class="p-0 p-sm-1 p-md-4">
    <el-card v-if="this.$store.state.core.user">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/pages' }">Pages</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{ name: 'PageEdit', params: {id: $route.params.pageId}}"
        >{{$route.params.pageId}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$route.params.sectionId}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$route.params.fieldId}}</el-breadcrumb-item>
      </el-breadcrumb>
      <h1>Edit Field</h1>
      <el-form v-if="field">
        <el-form-item label="Field ID">
          <el-input :disabled="true" :value="$route.params.fieldId" />
        </el-form-item>
        <el-form-item label="Link" v-if="field.type == 'link'">
          <el-input
            class="my-2"
            :disabled="loading"
            v-model="field.value.text"
            placeholder="Link Label"
          />
          <el-input
            class="my-2"
            :disabled="loading"
            v-model="field.value.url"
            placeholder="Link Url"
          />
        </el-form-item>
        <el-form-item label="Image" v-else-if="field.type == 'img'">
          <vue-image class="my-2" :img="field.value" />
          <image-picker
            class="my-2"
            :disabled="loading"
            v-model="field.value"
            :multiple="typeof field.value == 'array'"
          ></image-picker>
        </el-form-item>
        <el-form-item v-else-if="field.type == 'wysiwyg'">
          <quill-editor
          ref="quillEditor"
    v-model="field.value"
  />
        </el-form-item>
        <el-form-item label="Text" v-else>
          <el-input
            autosize
            :disabled="loading"
            type="textarea"
            :rows="2"
            placeholder="Please input"
            v-model="field.value"
          ></el-input>
        </el-form-item>
        <el-button :loading="loading" class="w-100 my-2" type="primary" @click="editField">Save</el-button>
      </el-form>
    </el-card>
  </div>
</template>
<script>
var moment = require("moment");
import Vue from "vue";
import firebase from "firebase";
import { db } from "@/CUSTOM/helpers/firebase";
import imagePicker from "@/CORE/modules/MEDIA/ImagePicker";
import VueImage from "@/CORE/modules/MEDIA/VueImage";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

import UIkit from "uikit";

export default {
  name: "EditField",
  components: { VueImage, imagePicker, quillEditor },
  data() {
    return {
      componentID: null,
      fields: null,
      field: null,
      loading: false,
      content: null
    };
  },
  created() {},
  mounted() {
    db.collection("pages")
      .doc(this.$route.params.pageId)
      .collection("sections")
      .doc(this.$route.params.sectionId)
      .get()
      .then(snap => {
        this.fields = snap.data().fields;
        this.field = this.fields[this.$route.params.fieldId];
        // if (this.field.type == "wysiwyg") {
        //   setTimeout(() => {
              
        //     this.editor.invoke('setHtml', this.field.value, false);
        //   }, 20);
        // }
      });
  },
  methods: { 
    isImage(field) {
      if (field.value.small || field.value[0].small) {
        return true;
      } else {
        return false;
      }
    },
    editField() {
      this.loading = true;
      // if (this.field.type == "wysiwyg") {
      //   this.field.value = this.editor.invoke('getHtml');
      // }
      console.log(this.field);
      this.field.lastEdit = Math.floor(Date.now() / 1000);
      this.field.author = this.$store.state.core.user.uid;
      this.fields[this.$route.params.fieldId] = this.field;
      db.collection("pages")
        .doc(this.$route.params.pageId)
        .collection("sections")
        .doc(this.$route.params.sectionId)
        .update({ fields: this.fields })
        .then(res => {
          this.$store.dispatch("PUSH_NOTIFICATION", {
            title: "Field Saved",
            status: "success"
          });
          this.$router.push({
            name: "PageEdit",
            params: { id: this.$route.params.pageId }
          });
          this.updateEditTimestamp();
          this.loading = false;
        });
    },
    updateEditTimestamp() {
      db.collection("pages")
        .doc(this.$route.params.pageId)
        .update({ lastEdit: Math.floor(Date.now() / 1000) });
    }
  },
  computed: {
    type() {
      if (this.field) {
        if (this.field.type) {
          return this.field.type;
        } else {
          if (this.isImage(this.field)) {
            return "img";
          } else {
            return "text";
          }
        }
      } else {
        return null;
      }
    },
    editor() {
      if(this.$refs.quillEditor != null){
        return this.$refs.quillEditor.quill
      }else return null;
        
      }
  },
  beforeDestroy() {
    if(this.editor != null){
      this.editor.destroy();
    }
    
  }
};
</script>