<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card v-if="u">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/users' }">Gebruikers</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'UserDetail', params: { id: $route.params.id }}">{{u.displayName}}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'UserEdit', params: { id: $route.params.id }}">Edit</el-breadcrumb-item>
            </el-breadcrumb>
            <h1>Edit User</h1>
            <el-form>
                <el-form-item label="Full Name">
                    <el-input v-model="u.displayName" placeholder="Full Name"></el-input>
                </el-form-item>
                <el-form-item label="Firstname">
                    <el-input v-model="u.firstname" placeholder="Firstname"></el-input>
                </el-form-item>
                <el-form-item label="Lastname">
                    <el-input v-model="u.lastname" placeholder="Lastname"></el-input>
                </el-form-item>
                <el-form-item label="Email">
                    <el-input v-model="u.email" :disabled="true" placeholder="Email"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-switch
                        v-model="u.firstLogin"
                        active-color="#13ce66"
                        inactive-color="#ff4949"
                        active-text="Activated"
                        :active-value="false"
                        :inactive-value="true">
                    </el-switch>
                </el-form-item>
                <el-form-item label="role">
                    <el-select v-model="role" placeholder="Select">
                        <el-option
                        :key="i" v-for="(r, i) in userRoles"
                        :label="r"
                        :value="i">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button class="w-100" type="primary" :loading="loading" @click="update">Save</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import {db} from '@/CUSTOM/helpers/firebase'
    export default {
        name: 'UserDetail',
        data () {
            return {
                u: null,
                role: null,
                loading: false,
                userRoles: ["Gebruiker", "Moderator", "Beheerder"]
            }
        },
        methods: { 
            update () {
                this.loading = true
                Promise.all([db.collection('users').doc(this.$route.params.id).update(this.u), db.collection('users-secure').doc(this.$route.params.id).update({role: this.role+1})]).then(res => {
                    this.loading = false
                    this.$router.push('/users')
                })
            }
        },
        created () {
           db.collection('users').doc(this.$route.params.id).onSnapshot((snap) => {
               this.u = snap.data()
           })
           db.collection('users-secure').doc(this.$route.params.id).onSnapshot((snap) => {
               this.role = snap.data().role - 1
           })
           if(this.$store.state.core.user && this.$store.state.core.user.role >= 4){
                this.userRoles.push('Admin')
            }
           this.$store.watch((state) => state.core.user, () => {
               if(this.$store.state.core.user && this.$store.state.core.user.role >= 4){
                    this.userRoles.push('Admin')
                }
           })
           
        }
}
</script>