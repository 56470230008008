import Sponsors from './Sponsors'
import SponsorEdit from './SponsorEdit'
export const sponsorRoutes = [
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: Sponsors,
    title: 'Sponsors',
    icon: 'el-icon-goods',
    meta: { requiresAuth: true, minRole: 1}
  },
  {
    path: '/sponsor/bewerken/:id',
    name: 'SponsorEdit',
    component: SponsorEdit,
    meta: { requiresAuth: true, minRole: 2}
  }
];