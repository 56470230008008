// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './CORE/App'
import router from './CORE/router'
import './CORE/styles/main.scss'
import {auth} from '@/CUSTOM/helpers/firebase'
import store from './CUSTOM/store'
// import Vueditor from 'vueditor'
// import 'vueditor/dist/style/vueditor.min.css'
import Vuelidate from 'vuelidate';
Vue.use(Vuelidate)
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/nl'
import 'bootstrap/dist/css/bootstrap.css';
// import VueTrumbowyg from 'vue-trumbowyg';
// import 'trumbowyg/dist/ui/trumbowyg.css';
// Vue.use(VueTrumbowyg);


Vue.use(ElementUI, {locale})

Vue.config.productionTip = false

let config = {
  toolbar: [
    'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize',
    'bold', 'italic', 'underline', 'strikeThrough', 'links', 'divider', 'subscript', 'superscript',
    'divider', 'justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', '|', 'indent', 'outdent',
    'insertOrderedList', 'insertUnorderedList', '|', 'picture', 'switchView', 
  ],
  fontName: [
    {val: 'pf_handbook_problack'}, 
    {val: 'pf_handbook_probold'}, 
    {val: 'pf_handbook_promedium'},
    {val: 'nunito_sansregular'},
    {val: 'nunito_sansitalic'},
    {val: 'nunitoregular'}
  ],
  fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
  uploadUrl: ''
};

// Vue.use(Vueditor, config);


const unsubscribe = auth.onAuthStateChanged((user) => {
  new Vue({
    el: '#app',
    router,
    template: '<App/>',
    render: h => h(App),
    store: store,
    created() {
      auth.onAuthStateChanged((user => {
        if(user) {
          this.$store.dispatch('SET_USER', user)
        } else {
          this.$store.dispatch('UNSET_USER')
        }
      }))
    }
  })
  unsubscribe();
});










