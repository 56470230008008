<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card class="mb-4" v-if="u">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/users' }">Gebruikers</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'UserDetail', params: { id: $route.params.id }}">{{u.displayName}}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-card>
        <user-details :u="u"></user-details>
    </div>
</template>
<script>
import {db} from '@/CUSTOM/helpers/firebase'
import UserDetails from '@/CUSTOM/components/UserDetails'
    export default {
        name: 'UserDetail',
        components: {UserDetails},
        data () {
            return {
                u: null
            }
        },
        methods: { 
            update () {
                db.collection('users').doc(this.$route.params.id).set(this.u).then(res => {
                    this.$router.push('/users')
                })
            }
        },
        created () {
           db.collection('users').doc(this.$route.params.id).onSnapshot((snap) => {
               this.u = snap.data()
           })
        }
}
</script>