<template>
    <div class="top-header">
        <el-header class="p-0">
            <el-menu  :default-active="$route.path"  class="el-menu-top" mode="horizontal">
                <el-menu-item index="0">
                    <router-link to="/"><img class="header-logo" :src="$logo" /></router-link>
                </el-menu-item>
                <el-submenu class="float-right"  v-if="$store.state.core.user" index="/profile">
                    <template slot="title">{{$store.state.core.user.displayName}}</template>
                    <el-menu-item index="/profile"><router-link to="/profile">Profile</router-link></el-menu-item>
                    <el-menu-item class="text-danger" @click="signOut" index="/signout">Sign out</el-menu-item>
                </el-submenu>
            </el-menu>
        </el-header>
      </div>
</template>
<script>
import {db, auth, functions} from '@/CUSTOM/helpers/firebase'

export default {
    name: 'TopHeader', 
    data() {
        return {
            activeIndex: "1",
            dialogSessionVisible: false,
            newSessionName: ''
        }
    },
    methods: { 
        signOut(){
            auth.signOut().then(() => {
                this.$router.push('/login')
            })
        }
    }
}
</script>
<style>

.header-logo {
  height: 70%;
}
.top-header{
  width: 100vw;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  z-index: 9;
}
</style>