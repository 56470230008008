
import {db, auth} from '@/CUSTOM/helpers/firebase'
import router from '@/CORE/router'
import { Notification } from 'element-ui'


const state = {
  user: null,
  loading: true,
  version: '1.0.0',
  config: null
};

const mutations = {
  SET_USER_MUTATION: function(state, user) {
    state.user = user
  },
  SET_LOADING: function(state, s) {
    state.loading = s
  },
  SET_CONFIG_DATA: function(state, c) {
    state.config = c
  }
};

const actions = {
  SET_USER: function({commit, dispatch}, user) {
    dispatch('LOAD_CONFIG')
    var u = {}
    db.doc('users/' + user.uid).get().then((snap) => {
      u = snap.data();
      u.uid = user.uid
      db.doc('users-secure/' + user.uid).get().then((s) => {
        u.role = s.data().role
        user.providerData.forEach(d => {
          if(d.providerId == 'facebook.com') {
            u.photoURL = "https://graph.facebook.com/" + d.uid + "/picture?height=50";
          }
        })
        commit('SET_USER_MUTATION', u)
        dispatch('IS_LOADING', false)
        
      })
    })
  },
  LOAD_CONFIG: function({commit, dispatch}) {
    db.collection('preferences').onSnapshot((snap) => {
      var config = {}
      snap.forEach(doc => {
        config[doc.id] = doc.data();
      })
      commit('SET_CONFIG_DATA', config)
    })
  },
  UNSET_USER: function({commit, dispatch}) {
    commit('SET_USER_MUTATION', null)
    dispatch('IS_LOADING', false)
  },
  IS_LOADING: function({commit}, s) {
    commit('SET_LOADING', s)
  },
  PUSH_NOTIFICATION: function({commit, dispatch}, notif) {
    Notification({
      title: notif.title,
      message: notif.message,
      type: notif.status,
      offset: 64,
      duration: notif.duration ? notif.duration : 4500
    });
  }
};


export default {
  state,
  mutations,
  actions
};
