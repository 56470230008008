<template>
    <div class="d-flex flex-wrap">
        <general-user-data :u="u"></general-user-data>
    </div>
</template>
<script>
import GeneralUserData from '@/CORE/modules/USERS/GeneralUserData';
export default {
    name:'userDetails',
    props:['u'],
    components: {GeneralUserData}
}
</script>

