import Overview from './Overview'

export const mediaRoutes = [
    {
      path: '/media',
      name: 'Media',
      title: 'Media',
      icon: 'el-icon-picture',
      component: Overview,
      meta: { requiresAuth: true, minRole: 1}
    }]