<template>
  <div id="app">
    <div v-if="$store.state.core.loading" class="preloader">
        <svg class="spinner" viewBox="0 0 50 50">
            <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
        </svg>
    </div>
    <el-container class="main-container is-vertical p-0" v-if="$store.state.core.user && !$store.state.core.user.firstLogin" >
      <top-header></top-header>
      <el-container>
        <side-menu></side-menu>
          <el-main class="page-content">
            <transition name="fade" mode="out-in">
              <router-view></router-view>
            </transition>
          </el-main>
        </el-container>
    </el-container>
    <el-container v-else>
      <el-main class="page-content-full">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </el-main>
    </el-container>
  </div> 
  
</template>

<script>
import TopHeader from '@/CORE/components/TopHeader'
import SideMenu from '@/CORE/components/SideMenu'
export default {
  name: 'app',
  components: {TopHeader, SideMenu},
  data () {
    return {
      isLoading: true,
      
    }
  },
  methods: { 
    toggleLoading(l){
      this.$store.dispatch('IS_LOADING', l)
    }
  }
}
</script>

