import Vue from 'vue';
import Vuex from 'vuex';

import core from '@/CORE/store';
import settings from '@/CUSTOM/store/modules/settings';
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const vuexLocal = new VuexPersistence ({
  storage: window.localStorage,
  reducer: state => ({user: state.core.user})
})

const store = new Vuex.Store({
  modules: {
    core, 
    settings
  },
  plugins: [vuexLocal.plugin]
});

export default store