<template>
    <div class="h-100">
        <el-card v-if="!success" class="col-12 col-sm-6 col-md-4 mx-auto vertical-center">
            <h3>Forgot Password</h3>
            <el-form>
                <el-form-item>
                    <el-input type="email" :class="$v.email.$error ? 'invalid-input' : ''" name="email" v-model="email" placeholder="Your Email"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-alert
                        v-if="error"
                        :title="error"
                        type="error">
                    </el-alert>
                    <el-button type="primary" class="w-100" @click.prevent="ForgotPassword" :loading="loading">Request</el-button>
                </el-form-item>
                <el-button class="w-100" type="text" :disabled="loading" @click="$router.push('login')">Login</el-button>
                </el-form>
        </el-card>
            <el-card v-if="success" class="col-12 col-sm-6 col-md-4 mx-auto py-5 vertical-center text-center">
                <h3>Email send!</h3>
                <el-button class="w-100" type="text" :disabled="loading" @click="$router.push('login')">Login</el-button>
            </el-card>
    </div>
</template>
<script>
import {db, auth, functions} from '@/CUSTOM/helpers/firebase'
import {required, email} from 'vuelidate/lib/validators'
    export default {
        name: 'ForgotPassword',
        data () {
            return {
                email: '',
                error: null,
                success: false,
                loading: false
            }
        },
        created() {
            this.$emit('loading', false)
        },
        methods: { 
            ForgotPassword () {
                if(!this.$v.$invalid) {
                    this.loading=true
                    functions.httpsCallable('userForgotPassword')({email: this.email}).then((res) => {
                        if(!res.data.error) {
                            auth.sendPasswordResetEmail(this.email).then(() => {
                                this.success = true
                                this.loading = false
                            }).catch((error) => {
                                this.loading = false
                                this.error = error.message
                                if(error.code == 'auth/user-not-found') {
                                    this.success = true
                                }
                            })
                        } else {
                            this.loading = false
                            this.error = res.data.error
                        }
                        
                    }).catch(error => {
                        this.success = true
                        this.loading = false
                    })
                } else this.$v.$touch();
            }
        },
        validations: {
            email: {
                required,
                email
            }
        }
    }
</script>