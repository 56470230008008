import Pages from './Pages'
import PageEdit from './PageEdit'
import EditField from './EditField'

export const pageRoutes = [
    {
      path: '/',
      name: 'Pages',
      component: Pages,
      meta: { requiresAuth: true, minRole: 1}
    },
    {
      path: '/pages',
      name: 'Pages',
      title: "Pagina's",
      icon: 'el-icon-tickets',
      component: Pages,
      meta: { requiresAuth: true, minRole: 1}
    },
    {
      path: '/page/:id',
      name: 'PageEdit',
      component: PageEdit,
      meta: { requiresAuth: true, minRole: 2}
    },
    {
      path: '/page/:pageId/section/:sectionId/field/:fieldId',
      name: 'EditField',
      component: EditField,
      meta: { requiresAuth: true, minRole: 2}
    }]