<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card>
            <el-form v-if="preferences && preferences.generalData">
                <h1>General Settings</h1>
                <el-form-item label="Name">
                    <el-input placeholder="Name" v-model="preferences.generalData.displayName" />
                </el-form-item>
                <el-form-item label="Website Link">
                    <el-input placeholder="Website Link" v-model="preferences.generalData.websiteLink" />
                </el-form-item>
                <el-form-item label="Signup Link">
                    <el-input placeholder="Signup Link" v-model="preferences.generalData.signupPrefix" />
                </el-form-item>
                <el-form-item label="Contact Email">
                    <el-input placeholder="Contact Email" v-model="preferences.generalData.contactEmail" />
                </el-form-item>
                <el-form-item label="No Reply Email">
                    <el-input placeholder="No Reply Email" v-model="preferences.generalData.noReplyEmail" />
                </el-form-item>
                <el-form-item label="Sendgrid Account Created Template">
                    <el-input placeholder="Sendgrid Account Created Template" v-model="preferences.generalData.accountCreatedTemplate" />
                </el-form-item>
                <el-form-item label="Sendgrid Contact Form Template">
                    <el-input placeholder="Sendgrid Contact Form Template" v-model="preferences.generalData.contactedTemplate" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" class="w-100" @click="update" :loading="loading">Save</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import Vue from 'vue'
import {db} from '@/CUSTOM/helpers/firebase'
    export default {
        name: 'Configuratie',
        data () {
            return {
                preferences: null,
                loading: false
            }
        },
        methods: { 
            update () {
                this.loading = true
                var promises = []
                Object.keys(this.preferences).forEach(k => {
                    promises.push(db.collection('preferences').doc(k).set(this.preferences[k], {merge: true}))
                })
                Promise.all(promises).then(res => {
                    this.loading = false
                }).catch(error => {
                    this.loading = false
                    throw error
                })
            }
        },
        created () {
           db.collection('preferences').onSnapshot((snap) => {
               this.preferences = {}
               snap.forEach(doc => {
                   Vue.set(this.preferences, doc.id, doc.data())
               })
               if(!this.preferences.generalData){
                   this.preferences.generalData = {}
               }
           })
        }
}
</script>