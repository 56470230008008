<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/organisaties' }">Organisaties</el-breadcrumb-item>
            </el-breadcrumb>
            <h1 class="uk-align-left">Organisaties</h1>
            <div class="uk-align-right">
                <el-button @click="downloadLogos"  icon="el-icon-picture-outline" type="primary">Download logo's</el-button>
                <el-button @click="getExcel"  icon="el-icon-download" type="primary">Download Excel</el-button>
                <el-button @click="$router.push('/organisatie/bewerken/0')"  icon="el-icon-plus" circle type="primary"></el-button>
            </div>
            <el-table
                :data="organisations"
                style="width: 100%">
                <el-table-column
                fixed
                prop="name"
                label="Naam">
                </el-table-column>
                <el-table-column
                    label="Laatste Update">
                    <template slot-scope="scope">
                        <i class="el-icon-time"></i>
                        <span style="margin-left: 10px">{{ convertDate(scope.row.lastEdit) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    v-if="$store.state.core.user.role >= 2"
                    fixed="right"
                    label="Bewerken" width="120">
                    <template slot-scope="scope">
                        <el-button type="success" @click="$router.push(`/organisatie/bewerken/${scope.row.id}`)" circle size="medium" icon="el-icon-edit"></el-button>
                        <el-popover
                            v-if="$store.state.core.user.role >= 3"
                            placement="top"
                            width="300"
                            trigger="hover">
                            <p><strong>Ben je zeker dat je deze organisatie wil verwijderen?</strong><br> Dit kan niet ongedaan gemaakt worden.</p>
                            <div style="text-align: right; margin: 0">
                                <el-button type="primary" size="mini" @click="remove(scope.row.id)">Ja</el-button>
                            </div>
                            <el-button slot="reference" type="danger" circle size="medium" :icon="loadings[scope.row.id] ? 'el-icon-loading' : 'el-icon-delete'"></el-button>
                        </el-popover>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
            
</template>
<script>
var moment = require('moment');
import {db} from '@/CUSTOM/helpers/firebase'
import imagePicker from '@/CORE/modules/MEDIA/ImagePicker'
import VueImage from '@/CORE/modules/MEDIA/VueImage'
import Vue from 'vue';
import XLSX from 'xlsx';
export default {
    name: 'Organisaties',
    components: {imagePicker, VueImage},
    data () {
        return {
            organisations: [],
            editing: false,
            loading: false,
            loadings: {}
        }
    },
    created () {
        db.collection('organisations').onSnapshot((snap) => {
            this.organisations = []
            snap.forEach((doc) => {
                var t = doc.data();
                t.id = doc.id;
                Vue.set(this.loadings, t.id, false)
                this.organisations.push(t)
            })
        })
    },
    methods: { 
        convertDate (timestamp) {
            return moment.unix(timestamp).fromNow();
        },
        remove (id) {
            
            return db.collection('organisations').doc(id).delete().then(() => {
                UIkit.notification({message: 'Organisatie Verwijderd', status: 'danger'})
            })
        
        },
        downloadLogos () {
            this.organisations.forEach(q => {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    var blob = xhr.response;
                    this.saveByteArray(blob, `${q.id}.png`)
                };
                xhr.open('GET', q.logo.url);
                xhr.send();
            })
        },
        getExcel () {
            this.downloading = true
            var res = this.prepareData()
            var ws = XLSX.utils.aoa_to_sheet(res);
            ws['!autofilter'] = { ref: `A1:${toColumnName(Object.keys(res[0]).length)}${res.length}` };
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Organisaties DVDA");
            XLSX.writeFile(wb, `organisaties_dvda.xlsx`);
            this.downloading = false
            return true
            
        },
        boolToString(bool){
            if(bool){
                return 'ja';
            } else {
                return 'nee';
            }
        },
        saveByteArray(blob, url){
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        prepareData() {
            var dataArray = []
            var data = {}
            var keys = ['id',  'Naam', 'Beschrijving', 'Organiserend', 'Contactpersoon', 'Email', 'Adres', 'Telefoonnummber', 'Website', 'Aantal animatoren', 'Logo']
            dataArray.push(keys)
            this.organisations.forEach(d => {
                var r = [d.ID, d.name, d.description || '', this.boolToString(d.organiserend), d.contact.name, d.contact.email, d.contact.address, d.contact.phone, d.contact.website, d.amount, d.logo.url]
                dataArray.push(r)
            })               
            return dataArray  
            
        },
    }
}
function toColumnName(num) {
  for (var ret = '', a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode(parseInt((num % b) / a) + 65) + ret;
  }
  return ret;
}
</script>