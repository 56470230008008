<template>
    <div class="p-4" v-if="$store.state.core.user">
        <el-card class="col-12 col-md-6">
            <img class="round" :src="$store.state.core.user.photoURL" />
            <h1>{{$store.state.core.user.displayName}}</h1>
            <p><i class="el-icon-message p-3"></i>{{$store.state.core.user.email}}</p>
            <p v-if="!$store.state.core.user.facebookLinked"><i class="el-icon-close text-danger p-3"></i>Not linked with Facebook</p>
            <p v-if="$store.state.core.user.facebookLinked"><i class="el-icon-check text-success p-3"></i>Linked with Facebook</p>
            <el-button type="primary" v-if="!$store.state.core.user.facebookLinked" @click="linkFacebook">Link with Facebook</el-button>
            <el-button type="text" @click="$router.push('change-password')">Change Password</el-button>
        </el-card>
    </div>
</template>
<script>
    import firebase from 'firebase'
    import {db, auth} from '@/CUSTOM/helpers/firebase'

    var facebook = new firebase.auth.FacebookAuthProvider()
    export default {
        name: 'Profile',
        methods: { 
            linkFacebook () {
                auth.currentUser.linkWithPopup(facebook).then((result) => {
                    var credential = result.credential;
                    var user = result.user;
                    return db.collection('users').doc(auth.currentUser.uid).update({facebookLinked: true})
                }).then(()=> {
                    console.log('done')
                }).catch((error) => {
                    console.log(error)
                    if(error.code == 'auth/provider-already-linked'){
                        db.collection('users').doc(auth.currentUser.uid).update({facebookLinked: true}).then(() => {})
                    }
                });
            }
        }
        
    }
</script>