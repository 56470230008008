import Vue from 'vue'
import Router from 'vue-router'
import Login from '../modules/USERS/Login'
import Profile from '../modules/USERS/Profile'
import Users from '../modules/USERS/Users'
import UserDetail from '../modules/USERS/UserDetail'
import UserEdit from '../modules/USERS/UserEdit'
import FirstLogin from '../modules/USERS/FirstLogin'
import ForgotPassword from '../modules/USERS/ForgotPassword'
import ChangePassword from '../modules/USERS/ChangePassword'
import Configuration from '../pages/Configuration'


import App from '../App'
import {auth, db} from '@/CUSTOM/helpers/firebase'
import store from '@/CUSTOM/store'
import { coreRoutes } from '../modules/routes';
import { variableRoutes } from '@/CUSTOM/custom-modules/routes';

Vue.use(Router)

var routes = {
  mode: 'history',
  routes: [
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
      meta: { requiresAuth: true, minRole: 1 }
    },
    {
      path: '/users',
      name: 'Users',
      title: 'Gebruikers',
      icon: 'el-icon-view',
      component: Users,
      meta: { requiresAuth: true, minRole: 3 }
    },
    {
      path: '/user/view/:id',
      name: 'UserDetail',
      component: UserDetail,
      meta: { requiresAuth: true, minRole: 3 }
    },
    {
      path: '/user/edit/:id',
      name: 'UserEdit',
      component: UserEdit,
      meta: { requiresAuth: true, minRole: 3 }
    },
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { requiresAuth: false}
    },
    {
      path: '/signup/:pass?/:email?',
      name: 'Signup',
      component: FirstLogin,
      meta: { requiresAuth: false}
    },
    {
      path: '/settings',
      name: 'Configuratie',
      component: Configuration,
      meta: { requiresAuth: true, minRole: 4 }
    },
    {
      path: '/password-forgotten',
      name: 'ForgotPassword',
      component: ForgotPassword,
      meta: { requiresAuth: false}
    },
    {
      path: '/change-password',
      name: 'ChangePassword',
      component: ChangePassword,
      meta: { requiresAuth: false, minRole: 1}
    }
    
  ]
}
routes.routes.push.apply(routes.routes, coreRoutes)
routes.routes.push.apply(routes.routes, variableRoutes)
const router = new Router(routes)

router.beforeEach((to, from, next) => {
  // IF USER WANTS TO NAVIGATE AWAY FROM SIGNUP
  // ONLY ALLOW IF LINK IS NOT VALID --> USER IS NOT LOGGED IN
  if (!to.matched.length) {
    next('/');
  } else {
    next();
  }
  if (from.name === 'Signup') {
    if(auth.currentUser) {
      db.doc('users/'+auth.currentUser.uid).get().then((snap) => {
        if(snap.data().firstLogin) {
          next(false)
        } else {
          next()
        }
      })
    } else {
      next()
    }
  }
  // CHECK IF PAGE REQUIRES AUTHENTICATION
  else if (to.matched.some(record => record.meta.requiresAuth)) {
    // CHECK IF USER IS LOGGED IN
    if(auth.currentUser) {
      // CHECK IF USER IS SAVED IN STORE
      if(!store.state.user) {
        // GET USER FROM DATABASE
        db.doc('users/'+auth.currentUser.uid).get().then((snap) => {
          if(snap.data().firstLogin) {
            next({path: 'signup'})
          }
          else {
            db.doc('users-secure/'+auth.currentUser.uid).get().then((s) => {
              if(s.data().role >= to.meta.minRole) {
                next()
              } else {
                next({path: 'login'})
              }
            })
          } 
        })
      } else {
        if(store.state.user.firstLogin) {
          next({path: 'signup'})
        }
        else if(store.state.user.role >= to.meta.minRole) {
          next()
        } else {
          next({path: 'login'})
        }
      }
      
    } else {
      next({path: 'login'})
    }
  } 
  else {
    if(to.fullPath == "/login" && auth.currentUser) {
      next({path: '/'})
    } else {
      next()
    }
  }
})

export default router
