
import {pageRoutes} from '../modules/PAGES/routes'
import {mediaRoutes} from '../modules/MEDIA/routes'
import {contactRoutes} from '../modules/CONTACT/routes'
import {blogRoutes} from '../modules/BLOG/routes'

var r = [];

r.push.apply(r, pageRoutes)
r.push.apply(r, mediaRoutes)
r.push.apply(r, contactRoutes)
r.push.apply(r, blogRoutes)

export const coreRoutes = r