<template>
    <el-card class="col-12 col-md-4" v-if="u">
        <div class="float-right">
            <el-button circle type="primary" icon="el-icon-edit" @click="$router.push({ name: 'UserEdit', params: { id: $route.params.id }})"></el-button>
        </div>
        <h1 > {{u.displayName}}</h1>
        <img class="round" :src="u.photoURL" />
        <p><i class="el-icon-message p-3"></i>{{u.email}}</p>
        <div>
            <p v-if="u.facebookLinked"><i class="el-icon-check text-success p-3"></i>Linked with Facebook</p>
            <p v-else><i class="el-icon-close text-danger p-3"></i>Not linked with Facebook</p>
        </div>
        <div>
            <p v-if="!u.firstLogin"><i class="el-icon-check text-success p-3"></i>Activated</p>
            <p v-else><i class="el-icon-close text-danger p-3"></i>Inactive</p>
        </div>
    </el-card>
</template>
<script>
export default {
    name: 'GeneralUserData',
    props: ['u']
}
</script>

