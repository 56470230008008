<template>
    <div class="h-100"> 
        <el-card class="col-12 col-sm-6 col-md-6 mx-auto vertical-center" v-if="loggedIn">
            <h3>Kies een Wachtwoord</h3>
            <el-form>
                <el-form-item>
                    <el-input type="password" :class="$v.newPassword.$error ? 'invalid-input' : ''" name="newPassword" v-model="newPassword" placeholder="New Password"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-alert
                        v-if="error"
                        :title="error.message"
                        type="error">
                    </el-alert>
                    <el-button type="primary" class="w-100" @click.prevent="ChangePassword" :loading="loading">Submit</el-button>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card class="col-12 col-sm-6 col-md-6 mx-auto vertical-center"  v-if="warning">
            <h3>Er ging iets fout</h3>
            <div class="my-3">
                <p v-if="$store.state.core.user">Je hebt je account nog niet geactiveerd. <br> Dit kan met de link die we naar {{$store.state.core.user.email}} hebben verzonden.</p>
                <p v-else>Je hebt je account nog niet geactiveerd. <br> Dit kan met de link die we naar je email-adress hebben verzonden.</p>
            </div>
            <el-button class="w-100" type="primary" @click.prevent="ChangeAccount">Different Account</el-button>
        </el-card>
        <el-card class="col-12 col-sm-6 col-md-6 mx-auto vertical-center"  v-if="error">
            <h3>Something went wrong.</h3>
            <div class="my-3">
                <el-alert
                        v-if="error"
                        title="The link you are using is not valid. Please use the link you received via email."
                        type="error">
                    </el-alert>
            </div>
            <el-button class="w-100" type="primary" @click.prevent="ChangeAccount">Different Account</el-button>
        </el-card>
    </div>
</template>
<script>
import {db, auth} from '@/CUSTOM/helpers/firebase'
import {required} from 'vuelidate/lib/validators'
export default {
    name: 'ChangePassword',
    data () {
        return {
            newPassword: '',
            error: null,
            loggedIn: false,
            warning: false,
            loading: false,
        }
    },
    created () {
        if(this.$route.params.email && this.$route.params.pass) {
            auth.signOut()
            auth.signInWithEmailAndPassword(this.$route.params.email, this.$route.params.pass).then(auth => {
                this.loggedIn = true
            }).catch(error => {
                var errorCode = error.code;
                var errorMessage = error.message;
                this.error = error
            });
        } else {
            if(!auth.currentUser) {
                this.$router.push('/login')
            } else {
                this.warning = true
            }
        }
        
    },
    methods: { 
        ChangePassword () {
            if(!this.$v.$invalid) {
                this.loading = true
                auth.currentUser.updatePassword(this.newPassword).then(auth => {
                    db.collection('users').doc(this.$store.state.core.user.uid).update({firstLogin: false}).then(res => {
                        this.$store.state.core.user.firstLogin = false
                        this.loading = false
                        this.$router.push('/')
                    })
                    
                }).catch(error => {
                    this.loading = false
                    var errorCode = error.code;
                    var errorMessage = error.message;
                    this.error = error
                    console.log(error)
                });
            } else this.$v.$touch();
        },
        ChangeAccount () {
            auth.signOut()
            this.$router.push('/login')
        }
    },
    validations: {
        newPassword: {
            required
        }
    }
    
}
</script>