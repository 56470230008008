<template>
    <div class="p-0 p-sm-1 p-md-4">
        <el-card v-if="o && $store.state.core.user">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/organisaties' }">Organisaties</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'OrganisatieEdit', params: { id: $route.params.id }}">{{o.name}}</el-breadcrumb-item>
            </el-breadcrumb>
            <h1>Bewerk Organisatie</h1>
            <el-form>
                <el-form-item label="Naam">
                    <el-input v-model="o.name" :disabled="editing" placeholder="Full Name"></el-input>
                </el-form-item>
                <el-form-item label="ID">
                    <el-input v-model="o.ID" :disabled="true" placeholder="ID"></el-input>
                </el-form-item>
                <el-form-item label="Logo">
                    <image-picker key="logo" :multiple="false" v-model="o.logo"></image-picker>
                    <vue-image :img="o.logo"/>
                </el-form-item>
                <el-form-item>
                    <el-checkbox v-model="o.organiserend">Organiserend</el-checkbox>
                </el-form-item>
                <el-form-item label="Foto's">
                    <image-picker :multiple="true" v-model="o.gallery"></image-picker>
                    <vue-image :img="o.gallery"/>  
                </el-form-item>
                <el-form-item label="Video">
                    <el-input v-model="o.videoUrl" :disabled="editing && $store.state.core.user.role <3" placeholder="Video url"></el-input>
                </el-form-item>
                <el-form-item label="Omschrijf  wat  jouw  organisatie  doet  in  max.  30  woorden:">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="Antwoord"
                        v-model="o.description">
                    </el-input>
                </el-form-item>
                <el-form-item label="Aantal  animatoren:">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="Antwoord"
                        v-model="o.amount">
                    </el-input>
                </el-form-item>
                <el-form-item label="Aantal  werkuren  van  een  animator  op  een  dag:">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="Antwoord"
                        v-model="o.engagement">
                    </el-input>
                </el-form-item>
                <el-form-item label="Aantal  kinderen  die  jullie  bereiken  op  een  dag:">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="Antwoord"
                        v-model="o.reach">
                    </el-input>
                </el-form-item>
                <h3>Interview animator</h3>
                <el-form-item>
                    <image-picker :multiple="false" v-model="o.interview.photo"></image-picker>
                    <vue-image :img="o.interview.photo"/> 
                </el-form-item>
                <el-form-item label="Wat  is  jouw  naam?">
                    <el-input
                        :rows="2"
                        placeholder="Antwoord"
                        v-model="o.interview.name">
                    </el-input>
                </el-form-item>
                <el-form-item label="Wat  vind  je  leuk  aan  animator  zijn?">
                    <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="Antwoord"
                        v-model="o.interview.fun">
                    </el-input>
                </el-form-item>
                <el-form-item label="Wat  was  je  leukste  ervaring/herinnering  als  animator?">
                    <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="Antwoord"
                        v-model="o.interview.memory">
                    </el-input>
                </el-form-item>
                <el-form-item label="Wat  was  het  leukste  moment  van  de  voorbije  zomer/vakantie?">
                    <el-input
                        type="textarea"
                        :rows="4"
                        placeholder="Antwoord"
                        v-model="o.interview.greatest">
                    </el-input>
                </el-form-item>
                <h3>Contact Gegevens</h3>
                <el-form-item label="Contact persoon">
                    <el-input
                        placeholder="Contact Persoon"
                        v-model="o.contact.name">
                    </el-input>
                </el-form-item>
                <el-form-item label="Telefoonnummer">
                    <el-input
                        placeholder="Telefoonnummer"
                        v-model="o.contact.phone">
                    </el-input>
                </el-form-item>
                <el-form-item label="Email">
                    <el-input
                        placeholder="Email"
                        v-model="o.contact.email">
                    </el-input>
                </el-form-item>
                <el-form-item label="Website">
                    <el-input
                        placeholder="Website"
                        v-model="o.contact.website">
                    </el-input>
                </el-form-item>
                <el-form-item label="Adres">
                    <el-input
                        type="textarea"
                        :rows="2"
                        placeholder="adres"
                        v-model="o.contact.address">
                    </el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="w-100" type="primary" :loading="loading" @click="update">Opslaan</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import {db} from '@/CUSTOM/helpers/firebase'
import imagePicker from '@/CORE/modules/MEDIA/ImagePicker'
import VueImage from '@/CORE/modules/MEDIA/VueImage'
import Vue from 'vue';
    export default {
        name: 'OrganisatieEdit',
        components: {imagePicker, VueImage},
        data () {
            return {
                    o: {
                    name: null,
                    ID: null,
                    organiserend: false,
                    interview: {},
                    contact: {},
                    gallery: []
                },
                editing: false,
                loading: false
            }
        },
        methods: { 
            update () {
                this.loading = true
                if(this.editing){
                    this.o.lastEdit = Math.floor(Date.now() / 1000)
                    db.collection('organisations').doc(this.$route.params.id).update(this.o).then(res => {
                        this.loading = false
                        this.$router.push('/organisaties')
                    })
                } else {
                    this.createID();
                    this.o.created = Math.floor(Date.now() / 1000)
                    this.o.lastEdit = Math.floor(Date.now() / 1000)
                    db.collection('organisations').doc(this.o.ID).set(this.o).then(res => {
                        this.loading = false
                        this.$router.push('/organisaties')
                    })
                }
                
            },
            createID(){
                this.o.ID = this.o.name.split(' ').join('-').toLowerCase();
            }
        },
        created () {
            if(this.$route.params.id == 0) {
                this.editing = false
            } else {
                this.editing = true
                db.collection('organisations').doc(this.$route.params.id).onSnapshot((snap) => {
                    this.o = snap.data()
                    if(!this.o.interview) {
                        this.o.interview = {}
                    }
                })
            }
           
           
        }
}
</script>