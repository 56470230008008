<template>
    <div>
        <el-button @click="open">{{multiple ? `Kies foto's` : `Kies foto`}}</el-button>
        <el-dialog :fullscreen="true" class="image-picker-dialog" :visible.sync="pickerVisible">
            <div slot="title">
                <h3>{{multiple ? `Kies foto's` : `Kies foto`}}</h3>
            </div>
            <div class="px-0 d-flex flex-wrap">
                <div class="col-12 col-sm-6 col-md-3 col-lg-2 p-1" :key="id" v-for="(m, id) in media">
                    <el-card class="cursor-pointer" :body-style="{padding: 0}">
                        <el-button v-if="findIndex(id) > -1" type="success" :icon="!multiple ? 'el-icon-check' : ''" class="corner-right" circle><span class="pr-1">{{multiple ? findIndex(id)+1 : ''}}</span></el-button>
                        <img class="w-100" @click.prevent="select(id, m)" :src="m.medium" alt="">
                    </el-card>
                </div>
            </div>
            <div class="p-0" slot="footer">
                <el-button type="primary" @click="choose" class="float-right">Kies</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import Vue from 'vue'
import {db, storage} from '@/CUSTOM/helpers/firebase'
export default {
    name:'ImagePicker',
    props: {
        'multiple':{
            default: true
        },
        'value': {}
    },
    data () {
        return {
            media: null,
            componentID: null,
            pickerVisible: false,
            selected: [],
            selectedKeys: {}
        }
    },
    created () {
        this.componentID = Math.floor((Math.random() * 100) + 1)

        db.collection('media').onSnapshot((snap) => {
            this.media = {}
            snap.forEach((doc) => {
                var t = doc.data();
                t.id = doc.id
                this.media[doc.id] = t
            })
        })
    },
    methods: { 
        open () {
            if(this.value) {
                if(!Array.isArray(this.value)) {
                    this.selected = [this.value]
                } else {
                    this.selected = this.value
                }
            } 
            this.pickerVisible = true
        },
        findIndex(id) {
            if(this.selected.length > 0 ){
                return this.selected.findIndex(x => x.id==id)
            } else return -1
        },
        select (id, m) {
            var f = this.findIndex(id)
            if(f != null && f!= -1) {
                this.selected.splice(f, 1)
            } else if (this.multiple) {
                this.selected.push(m)
            } else {
                this.selected = [m]
            }
        },
        choose () {
            if(!this.multiple) {
                this.$emit('input', this.selected[0])
            } else {
                this.$emit('input', this.selected)
            }
            this.pickerVisible = false
        }
    }
}
</script>
<style lang="scss">
    .image-picker-dialog {
        .el-dialog__body{
            padding-top: 0;
            padding-bottom: 0;
            overflow-x: scroll;
            height: 85vh;
        }
    }
</style>