<template>
    <div class="p-0 p-sm-1 p-md-4">
        <div v-if="this.$store.state.core.user">
            <el-card class="mb-4">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/pages' }">Pagina's</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ name: 'PageEdit', params: {id: $route.params.id}}">Bewerk Pagina</el-breadcrumb-item>
                </el-breadcrumb>
                <h1>Bewerk Pagina</h1>
            </el-card>
            <div>
                <el-card  class="mb-3" :key="id" v-for="(s, id) in sections">
                    <div class="">
                        <div class="float-left">
                            <h3>{{s.name}}</h3>
                        </div>
                        <div v-if="$store.state.core.user.role >= 4" class="float-right">
                            <el-button circle type="danger" @click="deleteSection(s.id)" icon="el-icon-delete"></el-button>
                        </div>
                    </div>
                     <el-table
                        :data="arraySections[s.id]"
                        style="width: 100%" :ref="`table-${s.id}`">
                        
                        <el-table-column
                        fixed
                        prop="key"
                        label="Naam">
                        </el-table-column>
                        <el-table-column
                        label="Waarde">
                            <template slot-scope="scope">
                                <div v-if="scope.row.type == 'link'"><a target="_blank" :href="scope.row.value.url">{{scope.row.value.text}}</a></div>
                                <div v-else-if="scope.row.type == 'wysiwyg'" v-html="scope.row.value"></div>
                                <div v-else-if="scope.row.type == 'img'"><vue-image :img="scope.row.value" :size="4"/></div>
                                <div v-else>{{scope.row.value}}</div>
                            </template>
                        </el-table-column>
                         <el-table-column
                            fixed="right"
                            label="Bewerken"
                            width="120">
                            <template slot-scope="scope">
                                <el-button type="success" circle size="medium" @click="$router.push(`/page/${$route.params.id}/section/${s.id}/field/${scope.row.key}`)" icon="el-icon-edit"></el-button>
                                <el-popover
                                v-if="$store.state.core.user.role > 3"
                                placement="top"
                                width="160"
                                trigger="hover">
                                <p>Ben je zeker?</p>
                                <div style="text-align: right; margin: 0">
                                    <el-button size="mini" type="text" @click="visible2 = false">Nee</el-button>
                                    <el-button type="primary" size="mini" @click="removeField(id, scope.row.key)">Ja</el-button>
                                </div>
                                <el-button slot="reference" type="danger" circle size="medium" icon="el-icon-delete"></el-button>
                                </el-popover>
                            </template>
                        </el-table-column>
                     </el-table>
                    <add-field-modal :section="id" :sections="sections" :page="$route.params.id" v-if="$store.state.core.user.role >= 3"></add-field-modal>
                </el-card>
                <el-button v-if="$store.state.core.user.role >= 4" class="mx-auto text-center d-block" type="success" @click="NewSectionDialog = true"><i class="el-icon-plus"></i> Add Section</el-button>
                
            </div>
        </div>
        <el-dialog title="New Section" :visible.sync="NewSectionDialog">
            <el-form>
            <el-form-item>
                <el-input v-model="newSection" autocomplete="off"></el-input>
            </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
            <el-button @click="NewSectionDialog = false">Cancel</el-button>
            <el-button type="primary" @click="addSection">New Section</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
var moment = require('moment');
import Vue from 'vue';
import firebase from 'firebase'
import {db} from '@/CUSTOM/helpers/firebase'
import imagePicker from '@/CORE/modules/MEDIA/ImagePicker'
import VueImage from '@/CORE/modules/MEDIA/VueImage'
import UIkit from 'uikit'
import AddFieldModal from './AddFieldModal'

export default {
    name: 'PageEdit',
    components: {imagePicker, VueImage, AddFieldModal},
    data () {
        return {
            page: null,
            sections: [],
            selectedSection: null,
            NewSectionDialog: false,
            newSection: null,
            editing: false,
            currentType: 'text',
            newField: {
                name: null,
                value: null
            }
        }
    },
    created () {
        db.collection('pages').doc(this.$route.params.id).onSnapshot((snap) => {
            this.page = snap.data()
        })
        db.collection('pages').doc(this.$route.params.id).collection('sections').orderBy("order").onSnapshot((snap) => {
            this.sections = []
            snap.forEach((doc) => {
                var p = doc.data()
                p.id = doc.id
                this.sections.push(p)            
            })
        })
    },
    methods: { 
        isImage(field) {
            if(field.value && ((field.value.url && field.value.large) || (field.value[0].url && field.value[0].large))) {
                return true
            } else {
                return false
            }
        },
        addSection () { 
            db.collection('pages').doc(this.$route.params.id).collection('sections').doc(this.newSection).get().then(doc => {
                if(doc.exists) {
                    this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Error!', message: 'A Section with this name already exists. Please choose a different name.', status: 'error', duration: 0})
                    throw new Error
                } else {
                    return db.collection('pages').doc(this.$route.params.id).collection('sections').doc(this.newSection).set({'lastEdit': Math.floor(Date.now() / 1000), 'author': this.$store.state.core.user.uid, name: this.newSection, order: 1 + Object.keys(this.sections).length})
                }
            }).then(() => {
                this.NewSectionDialog = false
                this.newSection = null
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Success!', message: 'Section added!', status: 'success'})
            }).catch(() => {
                this.NewSectionDialog = false
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Something went wrong.', message: 'Something went wrong while adding a new section.', status: 'error', duration: 0})
            })
        },
        cancel () {
            this.editing = false
            this.newField = {
                name: null,
                value: null
            }
        },
        deleteSection (id) {
            db.collection('pages').doc(this.$route.params.id).collection('sections').doc(id).delete().then(() => {
                this.$store.dispatch('PUSH_NOTIFICATION', {title:'Section removed!', status: 'error'})
                this.updateEditTimestamp()
            })  
        },
        removeField (id, key) {
            delete this.sections[id]['fields'][key]
            db.collection('pages').doc(this.$route.params.id).collection('sections').doc(this.sections[id].ID || this.sections[id].id).update(this.sections[id]).then(() => {
                this.$store.dispatch('PUSH_NOTIFICATION', {title: 'Field removed!', status: 'error'})
                this.updateEditTimestamp()
            })
        },
        updateEditTimestamp () {
            db.collection('pages').doc(this.$route.params.id).update({lastEdit: Math.floor(Date.now() / 1000)})
        }
    },
    computed: {
        arraySections () {
            var res = {}
            if(this.sections) {
                this.sections.forEach(s => {
                    if(s){
                        res[s.id] = []
                        if(s.fields){
                            Object.keys(s.fields).forEach(k => {
                                var t = s.fields[k]
                                t.key = k
                                res[s.id].push(t)
                            })
                        }
                        
                    }
                }) 
            }
            return res
        }
        
    }
}
</script>